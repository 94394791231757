<script setup>
import { computed } from 'vue'

const props = defineProps({
  todos: {
    type: Array,
    required: true
  }
})

const stats = computed(() => {
  const total = props.todos.length
  const completed = props.todos.filter(todo => todo.completed).length
  const pending = total - completed
  
  return { total, completed, pending }
})
</script>

<template>
  <div class="todo-stats">
    <div class="stat">
      <span class="label">Total:</span>
      <span class="value">{{ stats.total }}</span>
    </div>
    <div class="stat">
      <span class="label">Completed:</span>
      <span class="value">{{ stats.completed }}</span>
    </div>
    <div class="stat">
      <span class="label">Pending:</span>
      <span class="value">{{ stats.pending }}</span>
    </div>
  </div>
</template>

<style scoped>
.todo-stats {
  display: flex;
  justify-content: space-around;
  padding: 1rem;
  background-color: #2c2c2c;
  border-radius: 4px;
  margin-bottom: 2rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.stat {
  text-align: center;
}

.label {
  font-weight: bold;
  margin-right: 0.5rem;
  color: #ffffff;
}

.value {
  color: #a0a0a0;
}
</style>