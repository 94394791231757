export const validateTodoText = (text) => {
  const trimmedText = text.trim()
  return {
    isValid: trimmedText.length > 0 && trimmedText.length <= 100,
    trimmedText
  }
}

export const formatTodoText = (text) => {
  return text.charAt(0).toUpperCase() + text.slice(1)
}