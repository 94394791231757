import { defineStore } from 'pinia'
import { ref, watch } from 'vue'

const STORAGE_KEY = 'todo-app-items'

export const useTodoStore = defineStore('todos', () => {
  const todos = ref(JSON.parse(localStorage.getItem(STORAGE_KEY) || '[]'))

  watch(todos, (newTodos) => {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(newTodos))
  }, { deep: true })

  const addTodo = (text) => {
    todos.value.push({
      id: Date.now(),
      text,
      completed: false,
      createdAt: new Date().toISOString()
    })
  }

  const toggleTodo = (id) => {
    const todo = todos.value.find(todo => todo.id === id)
    if (todo) {
      todo.completed = !todo.completed
    }
  }

  const deleteTodo = (id) => {
    todos.value = todos.value.filter(todo => todo.id !== id)
  }

  return {
    todos,
    addTodo,
    toggleTodo,
    deleteTodo
  }
})