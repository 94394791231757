<script setup>
import { useTodoStore } from '../stores/todoStore'
import TodoItem from './TodoItem.vue'
import TodoInput from './TodoInput.vue'
import TodoStats from './TodoStats.vue'
import StorageIndicator from './StorageIndicator.vue'
import { storeToRefs } from 'pinia'
import { onMounted } from 'vue'

const todoStore = useTodoStore()
const { todos, loading, error } = storeToRefs(todoStore)

const handleAddTodo = (text) => {
  todoStore.addTodo(text)
}

onMounted(() => {
  todoStore.fetchTodos()
})
</script>

<template>
  <div class="todo-container">
    <h1>Todo App</h1>
    
    <TodoStats :todos="todos" />
    <TodoInput @add="handleAddTodo" :disabled="loading" />

    <div v-if="error" class="error-message">
      {{ error }}
    </div>

    <div v-if="loading" class="loading">
      Loading todos...
    </div>

    <div v-else class="todo-list">
      <TodoItem
        v-for="todo in todos"
        :key="todo.id"
        :todo="todo"
        @toggle="todoStore.toggleTodo(todo.id)"
        @delete="todoStore.deleteTodo(todo.id)"
      />
      
      <p v-if="todos.length === 0" class="empty-state">
        No todos yet. Add one above!
      </p>
    </div>

    <StorageIndicator />
  </div>
</template>

<style scoped>
.todo-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 2rem;
}

.todo-list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.empty-state {
  text-align: center;
  color: #666;
  font-style: italic;
  padding: 2rem 0;
}

.loading {
  text-align: center;
  color: #666;
  padding: 2rem 0;
}

.error-message {
  background-color: #ff44441a;
  color: #ff4444;
  padding: 1rem;
  border-radius: 4px;
  margin: 1rem 0;
  text-align: center;
}

h1 {
  text-align: center;
  margin-bottom: 2rem;
  color: #2c3e50;
}
</style>