<script setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { auth } from '../lib/firebase'
import { 
  signInWithEmailAndPassword, 
  createUserWithEmailAndPassword,
  signInAnonymously,
  GoogleAuthProvider,
  signInWithPopup
} from 'firebase/auth'

const router = useRouter()
const email = ref('')
const password = ref('')
const error = ref('')
const loading = ref(false)
const isSignUp = ref(false)

const handleGoogleSignIn = async () => {
  loading.value = true
  error.value = ''
  
  try {
    const provider = new GoogleAuthProvider()
    await signInWithPopup(auth, provider)
    router.push('/todos')
  } catch (e) {
    error.value = e.message.replace('Firebase: ', '')
  } finally {
    loading.value = false
  }
}

const handleSubmit = async () => {
  if (!email.value && !password.value && !isSignUp.value) {
    handleAnonymousSignIn()
    return
  }

  error.value = ''
  loading.value = true
  
  try {
    if (isSignUp.value) {
      await createUserWithEmailAndPassword(auth, email.value, password.value)
    } else {
      await signInWithEmailAndPassword(auth, email.value, password.value)
    }
    router.push('/todos')
  } catch (e) {
    error.value = e.message.replace('Firebase: ', '')
  } finally {
    loading.value = false
  }
}

const handleAnonymousSignIn = async () => {
  loading.value = true
  error.value = ''
  
  try {
    await signInAnonymously(auth)
    router.push('/todos')
  } catch (e) {
    error.value = e.message.replace('Firebase: ', '')
  } finally {
    loading.value = false
  }
}

const toggleForm = () => {
  isSignUp.value = !isSignUp.value
  error.value = ''
}
</script>

<template>
  <div class="landing-container">
    <h1>Welcome to Todo App</h1>
    <div class="auth-container">
      <h2>{{ isSignUp ? 'Create Account' : 'Sign In' }}</h2>
      
      <button 
        class="google-btn" 
        @click="handleGoogleSignIn"
        :disabled="loading"
      >
        <img src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg" alt="Google logo">
        Continue with Google
      </button>

      <div class="divider">
        <span>or</span>
      </div>
      
      <form @submit.prevent="handleSubmit" class="auth-form">
        <div class="form-group">
          <input
            v-model="email"
            type="email"
            placeholder="Email"
            :required="!isAnonymous"
            :disabled="loading"
          />
        </div>
        
        <div class="form-group">
          <input
            v-model="password"
            type="password"
            placeholder="Password"
            :required="!isAnonymous"
            minlength="6"
            :disabled="loading"
          />
        </div>

        <div v-if="error" class="error-message">{{ error }}</div>

        <div class="button-group">
          <button 
            type="submit" 
            class="submit-btn" 
            :disabled="loading"
          >
            <span v-if="loading">Please wait...</span>
            <span v-else>{{ isSignUp ? 'Sign Up' : 'Sign In' }}</span>
          </button>

          <button 
            v-if="!isSignUp" 
            type="button" 
            class="anonymous-btn" 
            @click="handleAnonymousSignIn"
            :disabled="loading"
          >
            Continue as Guest
          </button>
        </div>
      </form>

      <p class="toggle-text">
        {{ isSignUp ? 'Already have an account?' : "Don't have an account?" }}
        <button class="toggle-btn" @click="toggleForm" :disabled="loading">
          {{ isSignUp ? 'Sign In' : 'Sign Up' }}
        </button>
      </p>
    </div>
  </div>
</template>

<style scoped>
.landing-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 2rem;
  text-align: center;
}

.auth-container {
  background-color: #1a1a1a;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

h2 {
  color: rgba(255, 255, 255, 0.87);
  margin-top: 0;
  margin-bottom: 1.5rem;
}

.google-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.75rem;
  background-color: white;
  color: #333;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.google-btn:hover:not(:disabled) {
  background-color: #f5f5f5;
}

.google-btn img {
  width: 18px;
  height: 18px;
}

.divider {
  margin: 1.5rem 0;
  display: flex;
  align-items: center;
  text-align: center;
  color: rgba(255, 255, 255, 0.4);
}

.divider::before,
.divider::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.divider span {
  margin: 0 0.5rem;
  font-size: 0.875rem;
}

.auth-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.form-group {
  text-align: left;
}

input {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #333;
  border-radius: 4px;
  font-size: 1rem;
  background-color: #2c2c2c;
  color: rgba(255, 255, 255, 0.87);
  box-sizing: border-box;
}

input::placeholder {
  color: rgba(255, 255, 255, 0.4);
}

.button-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 0.5rem;
}

.submit-btn, .anonymous-btn {
  width: 100%;
  padding: 0.75rem;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.2s;
}

.submit-btn {
  background-color: #646cff;
  color: white;
}

.submit-btn:hover:not(:disabled) {
  background-color: #747bff;
}

.anonymous-btn {
  background-color: transparent;
  border: 1px solid #646cff;
  color: #646cff;
}

.anonymous-btn:hover:not(:disabled) {
  background-color: rgba(100, 108, 255, 0.1);
}

.error-message {
  color: #ff4444;
  font-size: 0.875rem;
  text-align: left;
}

.toggle-text {
  margin-top: 1rem;
  color: rgba(255, 255, 255, 0.6);
}

.toggle-btn {
  background: none;
  border: none;
  color: #646cff;
  font-weight: bold;
  cursor: pointer;
  padding: 0;
  margin-left: 0.5rem;
}

.toggle-btn:hover:not(:disabled) {
  text-decoration: underline;
}

button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

input:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}
</style> 