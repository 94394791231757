<script setup>
import { ref } from 'vue'
import { validateTodoText, formatTodoText } from '../utils/todoValidation'

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['add'])
const newTodo = ref('')
const error = ref('')

const addTodo = () => {
  const { isValid, trimmedText } = validateTodoText(newTodo.value)
  
  if (!isValid) {
    error.value = 'Todo must be between 1 and 100 characters'
    return
  }
  
  error.value = ''
  emit('add', formatTodoText(trimmedText))
  newTodo.value = ''
}
</script>

<template>
  <div class="todo-input">
    <div class="input-container">
      <input 
        v-model="newTodo" 
        @keyup.enter="addTodo"
        placeholder="Add a new todo"
        type="text"
        :class="{ 'error': error }"
        :disabled="disabled"
      />
      <button @click="addTodo" :disabled="disabled">Add</button>
    </div>
    <span v-if="error" class="error-message">{{ error }}</span>
  </div>
</template>

<style scoped>
.todo-input {
  margin-bottom: 2rem;
}

.input-container {
  display: flex;
  gap: 1rem;
}

input {
  flex: 1;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
}

input.error {
  border-color: #ff4444;
}

input:disabled {
  background-color: #f5f5f5;
  cursor: not-allowed;
}

button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.error-message {
  display: block;
  color: #ff4444;
  font-size: 0.875rem;
  margin-top: 0.5rem;
}
</style>