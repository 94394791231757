import { createRouter, createWebHistory } from 'vue-router'
import { auth } from '../lib/firebase'
import { onAuthStateChanged } from 'firebase/auth'
import LandingPage from '../components/LandingPage.vue'
import TodoList from '../components/TodoList.vue'

const routes = [
  {
    path: '/',
    name: 'landing',
    component: LandingPage
  },
  {
    path: '/todos',
    name: 'todos',
    component: TodoList,
    meta: { requiresAuth: true }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)

  onAuthStateChanged(auth, (user) => {
    if (requiresAuth && !user) {
      next('/')
    } else {
      next()
    }
  })
})

export default router 